import React, { useState } from 'react'
import brits from './../../../Assets/Images/brits.png'
import citygroup from './../../../Assets/Images/citygroup.jpg'

const Work = () => {
  const [activeWork, setActiveWork] = useState('citygroup')

  return (
    <div className='bg-[#0C0A09] w-full'>
      <div className='max-w-[1440px] mx-auto px-5 pb-20'>
        <h2 className='text-[42px] font-bold py-10 text-white'>Work</h2>
        <div className='flex rounded-[50px]'>
          <div className='bg-[#292524] rounded-l-[50px] min-h-[500px] h-auto w-1/4 relative overflow-hidden'>
            <div
              className={`flex items-center gap-5 p-5 hover:bg-[#1E293B] cursor-pointer ${activeWork === 'citygroup' ? 'bg-[#1E293B]' : ''}`}
              onClick={() => setActiveWork('citygroup')}
            >
              <img src={citygroup} alt='' className='w-[80px] rounded-full' />
              <p className='text-white font-bold text-[20px]'>City Group</p>
            </div>
            <div
              className={`flex items-center gap-5 p-5 hover:bg-[#1E293B] cursor-pointer ${activeWork === 'brits' ? 'bg-[#1E293B]' : ''}`}
              onClick={() => setActiveWork('brits')}
            >
              <img src={brits} alt='' className='w-[80px] rounded-full' />
              <p className='text-white font-bold text-[20px]'>BRITS</p>
            </div>
            <div
              className={`absolute left-0 transition-all duration-300 ease-in-out w-[5px] h-[120px] bg-sky-600 ${
                activeWork === 'brits' ? 'top-[120px]' : 'top-[0px]'
              }`}
              style={{ boxShadow: 'rgb(2, 132, 199) 0px 1px 10px 1px' }}
            ></div>
          </div>
          <div className='shadow-stone-300 backdrop-blur-sm bg-[linear-gradient(to_bottom_left,rgba(15,23,42,0.3),rgba(113,110,97,0.3))] rounded-r-[50px] w-3/4 px-5 pt-6 pb-10'>
            {activeWork === 'citygroup' && (
              <div>
                <h3 className='font-bold text-[24px] text-white'>
                  FringeCore_
                </h3>
                <h3 className='font-bold text-[18px] text-[#0EA5E9]'>
                  A child concern of City Group
                </h3>
                <div className='my-6 border border-[#0EA5E9] border-l border-t-0 border-r-0 border-b-0 relative p-3'>
                  <div className='bg-[#0EA5E9] h-3 w-3 border border-bright  rounded-full absolute -left-[6px] top-[20px]'></div>
                  <p className='text-[#0EA5E9] font-bold text-[18px]'>
                    Software Engineer
                  </p>
                  <p className='text-[#0EA5E9] font-bold'>
                    27/02/2024 - Present
                  </p>
                </div>
                <div className='text-white md:w-3/4'>
                  <p className='text-[18px]'>
                    City Group is one of Bangladesh’s foremost conglomerates and
                    a trusted name in consumer goods manufacturing. With a
                    52-year legacy, the group has grown significantly,
                    consistently adding value across its diverse range of
                    businesses. <br />
                    Today, City Group operates 40 sister concerns, each
                    excelling in distinct products and services. <br /> <br />{' '}
                    As a Software Engineer at City Group, I contributed to
                    several impactful projects: <br />
                    Developed web applications to streamline and manage the
                    group’s MIS (Management Information System). Contributed to
                    the creation of an industrial software solution for managing
                    plant operations at the LPG plant. Led the development of a
                    cloud-based ad screen marketing platform, helping to boost
                    sales and enhance the company’s market presence.
                  </p>
                </div>
              </div>
            )}
            {activeWork === 'brits' && (
              <div>
                <h3 className='font-bold text-[24px] text-white'>
                  BlackRock IT Soultions
                </h3>
                <div className='my-6 border border-[#0EA5E9] border-l border-t-0 border-r-0 border-b-0 relative p-3'>
                  <div className='bg-[#0EA5E9] h-3 w-3 border border-bright  rounded-full absolute -left-[6px] top-[20px]'></div>
                  <p className='text-[#0EA5E9] font-bold text-[18px]'>
                    Front-End Developer
                  </p>
                  <p className='text-[#0EA5E9] font-bold'>
                    01/01/2023 - 31/08/2024
                  </p>
                </div>
                <div className='text-white md:w-3/4'>
                  <p className='text-[#0EA5E9] font-bold text-[20px]'>
                    BlackRock IT Solutions: Your One-Stop Solution for All IT
                    Needs
                  </p>
                  <p className='text-[18px]'>
                    At BlackRock IT Solutions, I played a key role in developing
                    and delivering cutting- edge technology solutions across a
                    variety of projects: <br /> <br /> Developed the company’s
                    profile website using modern technologies, enhancing their
                    digital presence. <br />
                    Built a gaming app for Pickleball, contributing to the
                    growing popularity of the sport. <br /> Created a
                    comprehensive hospital management system web app,
                    streamlining medical operations and improving patient care.
                    Developed a tenant management application as part of an HR
                    management system, optimizing workforce management. <br />{' '}
                    Built multiple web applications using advanced technologies
                    like Next.js, ensuring scalability and performance.
                    Developed mobile applications with React Native, extending
                    the company’s reach to mobile platforms.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Work
